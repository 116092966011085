<template>
  <div class="content">
    <div class="content_wrap">
      <div class="content_crumbs">
        当前位置：<span style="cursor: pointer" @click="goIndex">首页 > </span>
        <span style="cursor: pointer" @click="goHistory">信息 > </span>
        <span style="cursor: pointer">正文</span>
      </div>

      <div class="content_content">
        <div class="content_content_title">
          {{ contentTitle }}
        </div>

        <div class="content_content_crumbs" :style="fixedPosition">
          <div class="content_content_crumbs_time">
            <img :src="contentCrumbs.timeIcon" />
            发布时间：{{ publishTime }}
          </div>
        </div>

        <div class="content_content_item_wrap">
          <div class="content_content_item">
            <p v-html="showHtml(content)"></p>
          </div>
        </div>

        <div class="content_content_fileInfo" v-for="fileItem in activeAttachList" :key="fileItem" @click="toHref(fileItem)">
          <div class="content_content_fileInfo_title">
            {{ fileItem.originalName }}
          </div>
          <div class="content_content_fileInfo_content">
            <img :src="contentIcon" />
            <div class="content_content_fileInfo_contetn_fileName">
              {{ fileItem.originalName }}
            </div>
          </div>
        </div>

        <div class="content_content_fileInfo" v-for="fileItem in files" :key="fileItem" @click="filestoHref(fileItem)">
          <div class="content_content_fileInfo_title">
            {{ fileItem.fileName }}
          </div>
          <div class="content_content_fileInfo_content">
            <img :src="contentIcon" />
            <div class="content_content_fileInfo_contetn_fileName">
              {{ fileItem.fileName }}
            </div>
          </div>
        </div>

        <div class="content_page_turner" v-if="haveTurner">
          <div class="content_page_turner_next">
            <span>下一篇</span>：2022年中央支持学前教育发展资金预算校舍维修改造项目招标公告
          </div>
          <div class="content_page_turner_before">
            <span>上一篇</span>：2022年中央支持学前教育发展资金预算校舍维修改造项目招标公告
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  computed,
  reactive,
  onMounted,
  onBeforeMount,
  onUnmounted,
} from "vue";
import imgData from "@/data";
import { stateStore, mainStore } from "@/store";
import { storeToRefs } from "pinia";
import { useRouter, useRoute } from "vue-router";

export default {
  props: ["haveFile", "haveTurner"],
  setup() {
    const showHtml = (str) => {
      return str
        ? str
          .replace(str ? /&(?!#?\w+;)/g : /&/g, "&amp;")
          .replace(/&lt;/g, "<")
          .replace(/&gt;/g, ">")
          .replace(/&quot;/g, '"')
          .replace(/&#39;/g, "'")
          // .replace(/.*?\}/g, "")
          .replace(/&amp;nbsp;/g, "\u3000")
        : "";
    };
    const toHref = (item) => {
      const a = document.createElement("a");
      a.href = location.origin + "/" + item.name;
      a.download = item.originalName;
      a.click();
    };
    const filestoHref = (item) => {
      const a = document.createElement("a");
      a.href = location.origin + "/" + item.fileUrl;
      a.download = item.fileName;
      a.click();
    };
    onBeforeMount(() => {
      window.addEventListener("scroll", () => {
        stateStore().setscrollX(document.documentElement.scrollTop);
      });
      const route = useRoute();
      stateStore().selectNoticeById(route.query.id);
      // if (route.query.type) {
      //   document.title = route.query.type;
      // }
    });
    onMounted(() => {
      const route = useRoute();
      const title = mainStore().checkTitle(route.query.id);
      console.log(title, "onMounted");
    });
    onUnmounted(() => {
      window.removeEventListener("scroll", () => {
        console.log("remove");
      });
    });
    // position: fixed;top:0;right:50px
    const fixedPosition = computed(() =>
      storeToRefs(stateStore()).scrollX.value > 400
        ? "position: fixed;top:0;right: 16%;width: 68%;"
        : ""
    );
    const router = useRouter();
    const RelevantTime = {
      createTime: "2022.07.01",
      startTime: "2022.07.03",
      endTime: "2022.09.01",
    };
    // const contentContent = [
    //   {
    //     title: "一、项目基本情况",
    //     content: `项目编号：   S1290803384087210项目名称：  海门市职教中心校园运动操场改造提升工程采购方式：   竞争性谈判       竞争性磋商         询价预算金额：  273097030 元最高限价：   273097030 元采购需求：校园运动操场改造 提升，相见招标清单合同履行期限：30日历天本项目   不接受   联合体投标`,
    //   },
    // ];.
    const storeOfstate = stateStore();
    const content = computed(() => {
      return storeToRefs(storeOfstate).activeHtml;
    });
    const files = computed(() => {
      return storeToRefs(storeOfstate).files;
    });

    // const content = computed(() => localStorage.getItem("activeHtml"));
    const contentTitle = computed(() => { return storeToRefs(storeOfstate).activeTitle; });
    const publishTime = computed(() => storeToRefs(storeOfstate).activePublishTime);
    // const contentTitle = computed(() => localStorage.getItem("activeTitle"));
    // const publishTime = computed(() =>
    //   localStorage.getItem("activePublishTime")
    // );

    const ActiveItem = computed(() => storeToRefs(storeOfstate).activeItem);
    // const ActiveItem = computed(() =>
    //   JSON.parse(localStorage.getItem("activeItem"))
    // );
    const activeAttachList = computed(
      () => storeToRefs(storeOfstate).activeAttachList
    );
    const goHistory = () => {
      router.go(-1);
    };
    const goToJoin = () => {
      window.open(process.env.VUE_APP_SEVERCE_PLATFORM);
    };
    const goIndex = () => {
      mainStore().setactivePageUrl("/index");
      router.push({
        path: "/index",
      });
    };
    const data = reactive({
      ...imgData.BiddingInfoImg,
      ...RelevantTime,
      content,
      contentTitle,
      publishTime,
      ActiveItem,
      activeAttachList,
      // contentContent,
      showHtml,
      files,
    });

    return {
      ...data,
      goHistory,
      goToJoin,
      goIndex,
      fixedPosition,
      toHref,
      filestoHref,
    };
  },
};
</script>

<style scoped>
@import url("./Content.css");
</style>

